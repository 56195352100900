import 'url-search-params-polyfill';

interface QueryParamsInterface {
  task_form_id: string;
  task_form_name: string;
  csrf_token: string;
  user_id: string;
  account_id: string;
  form_answer_id: string;
  new: string;
  edit: string;
  md5: string;
  lang: string;
  locale: string;
  state: string;
  devMode: string;
  share_token: string;
  first_form: boolean;
  site_key: string;
  evaluate: string;
  number_count_config: string;
  folder_id: string;
  template_id: string;
  webhook_id: string;
  email: string;
  sig_type: 'account';
  welcome: string;
  show_promo: string;
  promo_expire: string;
  discount: string;
  country: string;
  currency: string;
  pre_currency: string;
  monthly_stand_price: string;
  monthly_plus_price: string;
  duemint_url: string;
  stripe_id: string;
  current_users: string;
  import_error: string;
  limit_reached: string;
  finding: string;
  selected: string;
  signature_verified: string;
}

export const getQueryParams = (): QueryParamsInterface => {
  const params = new URLSearchParams(window.location.search);
  const paramObj: any = {};
  const paramsKeys = Array.from(params.keys());
  paramsKeys.forEach((value) => {
    paramObj[value] = params.get(value);
  });
  if (!['pt', 'es', 'en'].includes(paramObj['lang'])) {
    paramObj['lang'] = 'en';
  }
  return paramObj;
};

export function strongifyWord(string: string, word: string) {
  return string.replace(word, `<strong>${word}</strong>`);
}

// Adapted from https://gist.github.com/0x263b/2bdd90886c2036a1ad5bcf06d6e6fb37
export function stringToColor(string: string) {
  const hash = Array.from(string).reduce(
    (hash, char) => char.charCodeAt(0) + ((hash << 5) - hash),
    0,
  );

  const color = Array.from({ length: 3 }, (_, i) => {
    const value = (hash >> (i * 8)) & 0xff;
    return `00${value.toString(16)}`.slice(-2);
  }).join('');

  return `#${color}`;
}

export function sortByKey<T>(array: T[], key: keyof T, ascending: boolean = true): T[] {
  return [...array].sort((a, b) => {
    let comparison = 0;

    if (a[key] < b[key]) {
      comparison = -1;
    } else if (a[key] > b[key]) {
      comparison = 1;
    }

    return ascending ? comparison : comparison * -1;
  });
}
