import axios from 'axios';
import {
  FormAnswerSignatureState,
  SignatureBody,
  SignatureResponse,
  UserSignatureState,
} from '../types/signature';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;

// These requests are only used for account signature configuration.
// When the account config is migrated to React, this will be included
// in the account config API.

async function getSignature(type: string) {
  const response = await axios.get<SignatureResponse>(
    `signatures/${type === 'user' ? 'mobile_user_config' : 'account_config'}`,
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function updateAccountSignature(body: SignatureBody, userId: string) {
  const response = await axios.post(`users/update_react/${userId}`, body, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function getUserSignatureState() {
  const response = await axios.get<UserSignatureState>('signatures/mobile_user/state', {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function getFormAnswerSignatureState(formAnswerId: string) {
  const response = await axios.get<FormAnswerSignatureState>(
    `signatures/form_answer/${formAnswerId}`,
    {
      withCredentials: true,
    },
  );
  const { data } = response;
  return data;
}

async function signFormAnswer(formAnswerId: string) {
  const response = await axios.get(`signatures/form_answer/${formAnswerId}/sign`, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

export const signatureApi = {
  getSignature,
  updateAccountSignature,
  getUserSignatureState,
  getFormAnswerSignatureState,
  signFormAnswer,
};
