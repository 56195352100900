/* 
PENDING ENDPOINT CALLS:
match "mobile_users_react/create" => "mobile_users#create_react", via: [:post]
match "mobile_users_react/update/:id" => "mobile_users#update_react", via: [:put] // Para re-activar
match "mobile_users_react/invite" => "mobile_users#invite_react", via: [:post]
match "mobile_users_react/re_invite" => "mobile_users#re_invite_react", via: [:post]
*/

import axios from 'axios';
import {
  MobileUser,
  ModificationLog,
  MobileUserCreateBody,
  InviteUserBody,
  MobileUserUpdateBody,
  ActivatedMobileUserMinimumData,
} from '../types';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_ENDPOINT;

interface PaginationInterface {
  page?: number;
  search?: string;
}

interface FetchUsersInterface {
  page: number;
  total_pages: number;
  total_users: number;
  users: MobileUser[];
}
interface FetchLogsInterface {
  page: number;
  total_pages: number;
  total_users: number;
  users: ModificationLog[];
}

interface UserInfo {
  mobile_user: {
    id: number;
    name: string;
    last_name: string;
    code: string;
    email: string;
    phone: string;
    identifier: string;
    answers: number;
    chat: number;
    tasks: number;
    assigned: number;
    evaluate: number;
    metadata: number;
    modify: number;
    edit_forms: number;
    evaluate_delete: number;
    users: number;
    export: number;
  };
  group: { id: number[] };
  location: { id: number[] };
  form: { id: number[] };
  signature: {
    full_name: string;
    personal_email: string;
    rut: string;
    country: string;
  };
  pending_signature_forms: { id: number[] };
  pending_signature_groups: { id: number[] };
}

async function getActivatedMobileUsers({ page = 1, search }: PaginationInterface) {
  const response = await axios.get<FetchUsersInterface>(
    `/mobile_users_react/activated?page=${page}${search ? `?&search=${search}` : ''}`,
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function getAllActivatedMobileUsers() {
  const response = await axios.get<ActivatedMobileUserMinimumData[]>(`/mobile_users_react/all`, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function getDeactivatedMobileUsers({ page = 1 }: PaginationInterface) {
  const response = await axios.get<FetchUsersInterface>(
    `/mobile_users_react/deactivated?page=${page}`,
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function getMobileUsersHistory({ page = 1 }: PaginationInterface) {
  const response = await axios.get<FetchLogsInterface>(`/mobile_users_react/history?page=${page}`, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

// The delete function deactivates a user
async function deleteMobileUser(mobileUserId: number) {
  const response = await axios.delete<MobileUser>(`/mobile_users_react/delete/${mobileUserId}`, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function bulkDeactivateMobileUsers(mobileUserIds: number[]) {
  const response = await axios.delete<MobileUser>(`/mobile_users_react/bulk_delete`, {
    data: { mobile_user_ids: mobileUserIds },
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function updateMobileUser(body: MobileUserUpdateBody) {
  const response = await axios.put<MobileUser>(
    `mobile_users_react/update/${body.mobile_user.id}`,
    body,
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

// TODO: Find how to combine this function with the previous one, since they have the same purpose but the typing is different.
async function editMobileUser(updateMobileUserParams: MobileUserCreateBody, mobileUserId: string) {
  const response = await axios.put<MobileUser>(
    `mobile_users_react/update/${mobileUserId}`,
    updateMobileUserParams,
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

// You use the update function to edit and to activate users
async function reactivateMobileUser(mobileUserId: number) {
  const response = await axios.put<MobileUser>(
    `mobile_users_react/update/${mobileUserId}`,
    {
      mobile_user: {
        reactivate_user: 'true',
      },
      location: { id: [''] },
      form: { id: [''] },
    },
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function createMobileUser(body: MobileUserCreateBody) {
  const response = await axios.post<MobileUser>('mobile_users_react/create', body, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

async function validateNewUserEmail(email: string) {
  try {
    const response = await axios.get<{ email_exists: boolean }>('/validate_params', {
      params: { email },
    });
    return response.data;
  } catch (error) {
    console.log('There was an error validating the new email', error);
  }
}

async function inviteUser(body: InviteUserBody) {
  const response = await axios.post<{ error?: string; message?: string }>(
    'mobile_users_react/invite',
    body,
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function reInviteUser(mobileUserId: string) {
  const response = await axios.post<{ error?: string; message?: string }>(
    `mobile_users_react/re_invite`,
    { user_id: mobileUserId },
    { withCredentials: true },
  );
  const { data } = response;
  return data;
}

async function getUserInfo(mobileUserId: string) {
  const response = await axios.get<UserInfo>('/mobile_users_react/' + mobileUserId, {
    withCredentials: true,
  });
  const { data } = response;
  return data;
}

export const mobileUsersApi = {
  getActivatedMobileUsers,
  getAllActivatedMobileUsers,
  getDeactivatedMobileUsers,
  getMobileUsersHistory,
  deleteMobileUser,
  bulkDeactivateMobileUsers,
  updateMobileUser,
  reactivateMobileUser,
  createMobileUser,
  validateNewUserEmail,
  inviteUser,
  reInviteUser,
  getUserInfo,
  editMobileUser,
};
